import styled from "styled-components";
import {
  Grid,
  Button as MuiButton,
  TextField as MuiTextField,
} from "@mui/material";

export const App = styled.div`
  background: linear-gradient(
    hsla(47, 35%, 92%, 100%),
    hsla(47, 17%, 80%, 100%)
  );
  min-height: 100vh;
`;

export const Step1 = styled.div<{ $activeStep: number }>`
  align-items: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 50px hsla(37, 37%, 37%, 25%);
  margin: 0 auto;
  max-width: 590px;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: fixed;
  left: 50%;
  top: 50%;
  opacity: ${({ $activeStep }) => ($activeStep === 1 ? 1 : 0)};
  transition:
    opacity 0.25s ease,
    translate 0.5s ease;
  translate: ${({ $activeStep }) =>
    $activeStep === 1 ? "-50% -50%" : "-155% -50%"};
  padding: 32px 16px;
  width: calc(100% - 32px);

  @media screen and (min-width: 600px) {
    gap: 30px;
    padding: 32px;
    width: 100%;
  }
`;

const setStep2Location = ($activeStep: number) => {
  switch ($activeStep) {
    case 1:
      return "55% -50%";
      break;
    case 2:
      return "-50% -50%";
      break;
    case 3:
      return "-155% -50%";
  }
};

export const Step2 = styled(Step1)`
  opacity: ${({ $activeStep }) => ($activeStep === 2 ? 1 : 0)};
  translate: ${({ $activeStep }) => setStep2Location($activeStep)};
`;

export const Step3 = styled(Step1)`
  opacity: ${({ $activeStep }) => ($activeStep === 3 ? 1 : 0)};
  translate: ${({ $activeStep }) =>
    $activeStep === 3 ? "-50% -50%" : "55% -50%"};
`;

export const Logo = styled.img`
  display: block;
  margin: 0 auto;
  width: 140px;
`;

export const Title = styled.h1`
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 3.2rem;
  margin: 0;
`;

export const Description = styled.p`
  font-size: 1.6rem;
  text-align: center;
  margin: 0;
`;

export const TextField = styled(MuiTextField)<{ ref?: any }>`
  && {
    .MuiInputBase-input {
      font-family: "Beatrice", sans-serif;
      font-size: 1.4rem;
      padding: 12px;
    }

    fieldset {
      border: 1px solid #252525;
      border-radius: 5px;
    }

    // focus state
    &:focus-within fieldset {
      border: 1px solid #ff8166;
    }

    // error state
    .Mui-error .MuiInputBase-input {
      background: #fed8d0;
    }

    .Mui-error fieldset {
      border-color: #252525;
    }

    .MuiFormHelperText-root {
      color: #ff8166;
      font-family: "Beatrice", sans-serif;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
`;

export const TextFieldCentered = styled(TextField)`
  .MuiInputBase-input {
    text-align: center;
  }
`;

export const YourPassword = styled.h2`
  font-size: 1.4rem;
  font-weight: 600;
  margin-block: 0 5px;
`;

export const Rule = styled.div`
  align-items: top;
  display: flex;
  font-size: 1.2rem;
  gap: 5px;
  letter-spacing: -0.005em;
`;

export const HiddenGrid = styled(Grid)`
  animation: 0.25s slideOpen ease-out 1 both;

  @keyframes slideOpen {
    0% {
      max-height: 0;
    }

    100% {
      max-height: 90px;
    }
  }

  @media screen and (min-width: 600px) {
    animation: 0.25s slideOpenDesktop ease-out 1 both;
  }

  @keyframes slideOpenDesktop {
    0% {
      max-height: 0;
    }

    100% {
      max-height: 72px;
    }
  }
`;

export const Button = styled(MuiButton)`
  && {
    background: #ff8166;
    box-shadow: none;
    color: #252525;
    font-family: "Beatrice", sans-serif;
    font-size: 1.4rem;
    font-weight: 600;
    padding: 10px;
    text-transform: none;

    &:hover,
    &:active {
      background: #ff8166;
      box-shadow: none;
      color: #252525;
    }

    &.Mui-disabled {
      background: #efefef;
      color: #a1a1a1;
    }
  }
`;

export const Subtle = styled.span`
  color: #666;
  font-size: 1.2rem;
  text-align: center;

  a {
    color: #666;
  }
`;

export const CTA = styled.span`
  font-size: 1.6rem;
  font-weight: 600;
`;

export const RecaptchaContainer = styled.div`
  display: none;
`;

export const OtpForm = styled.form`
  width: 100%;
`;
