import Logo from "assets/logo-klover.svg";
import React, { forwardRef } from "react";
import { CUSTOM_EVENT_NAME } from "../constants";
import { Check, X } from "@phosphor-icons/react";
import {
  ConfirmationResult,
  User,
  createUserWithEmailAndPassword,
  linkWithPhoneNumber,
} from "firebase/auth";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import { InputMask, type InputMaskProps } from "@react-input/mask";
import { auth } from "firebase";
import { sendOnboardingEvent } from '../tracking';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Styled from "../App.styles";
import * as yup from "yup";

interface Step1Props {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  errorPhoneAlreadyExists: boolean;
  setConfirmationResult: React.Dispatch<
    React.SetStateAction<ConfirmationResult | undefined>
  >;
  setErrorPhoneAlreadyExists: React.Dispatch<React.SetStateAction<boolean>>;
}

const Step1 = ({
  activeStep,
  setActiveStep,
  setUser,
  setPhone,
  errorPhoneAlreadyExists,
  setConfirmationResult,
  setErrorPhoneAlreadyExists,
}: Step1Props) => {
  const [errorEmailAlreadyExists, setErrorEmailAlreadyExists] =
    React.useState(false);

  interface FormInputs {
    email: string;
    phone: string;
    password: string;
  }

  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/;

  const validationSchema = yup
    .object({
      email: yup.string().email().required(),
      phone: yup.string().min(4).required(),
      password: yup.string().matches(passwordRegex).min(8).required(),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      email: "",
      phone: "",
      password: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    sendOnboardingEvent({ action: CUSTOM_EVENT_NAME.FORM_VERIFICATION_FIRST });
    createUserWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        const user = userCredential.user;
        setUser(user);
        setPhone(data.phone);

        linkWithPhoneNumber(user, data.phone, window.recaptchaVerifier)
          .then((confirmationResult) => {
            setActiveStep(2);
            setConfirmationResult(confirmationResult);

          })
          .catch((error) => {
            console.error(error);
            user.delete();
          });
      })
      .catch((error) => {
        console.error(error);
        if (error.code === "auth/email-already-in-use") {
          setErrorEmailAlreadyExists(true);
        }
      });
  };

  const ForwardedInputMask = forwardRef<HTMLInputElement, InputMaskProps>(
    (props, forwardedRef) => {
      return (
        <InputMask
          ref={forwardedRef}
          mask="+1 (___) ___-____"
          replacement={{ _: /\d/ }}
          {...props}
        />
      );
    }
  );

  return (
    <Styled.Step1 $activeStep={activeStep}>
      <Styled.Logo src={Logo} />

      <Styled.Title>Join Klover today</Styled.Title>

      <Styled.Description>
        Let’s start with some basic information.
      </Styled.Description>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing="10px">
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Styled.TextField
                  fullWidth
                  placeholder="Email address"
                  error={!!errors.email || errorEmailAlreadyExists}
                  helperText={
                    errorEmailAlreadyExists
                      ? "Account with email already exists"
                      : null
                  }
                  onKeyUp={() => {
                    setErrorEmailAlreadyExists(false);
                  }}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <Styled.TextField
                  fullWidth
                  placeholder="Phone number"
                  InputProps={{
                    inputComponent: ForwardedInputMask,
                  }}
                  error={!!errors.phone || errorPhoneAlreadyExists}
                  helperText={
                    errorPhoneAlreadyExists
                      ? "Account with phone number already exists"
                      : null
                  }
                  onKeyUp={() => {
                    setErrorPhoneAlreadyExists(false);
                  }}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Styled.TextField
                  fullWidth
                  placeholder="Set password"
                  type="password"
                  error={!!errors.password}
                  {...field}
                />
              )}
            />
          </Grid>

          {Object.keys(errors).length > 0 && (
            <Styled.HiddenGrid item xs={12}>
              <Styled.YourPassword>Your password</Styled.YourPassword>

              <Styled.Rule>
                {errors.password?.type === "min" ? (
                  <X size={14} weight="bold" color="red" />
                ) : (
                  <Check size={14} weight="bold" color="green" />
                )}

                <span>Must be at least 8 characters</span>
              </Styled.Rule>

              <Styled.Rule>
                {errors.password?.type === "matches" ? (
                  <X size={14} weight="bold" color="red" />
                ) : (
                  <Check size={14} weight="bold" color="green" />
                )}

                <span>
                  Must contain 1 uppercase, 1 lowercase, 1 number and 1 special
                  character
                </span>
              </Styled.Rule>
            </Styled.HiddenGrid>
          )}

          <Grid item xs={12}>
            <Styled.Button
              fullWidth
              variant="contained"
              disabled={false}
              type="submit"
            >
              Next
            </Styled.Button>
          </Grid>
        </Grid>
      </form>

      <Styled.Subtle>
        By selecting Next, you agree to the{" "}
        <a href="https://www.joinklover.com/terms-and-conditions">
          terms & conditions
        </a>{" "}
        and{" "}
        <a href="https://www.joinklover.com/privacy-policy">privacy policy</a>.
      </Styled.Subtle>

      <Styled.CTA>
        <a href="https://cash.klover.app/PuAe/wa3naim5">
          Already have an account?
        </a>
      </Styled.CTA>

      {/* <Styled.Subtle>
        See <a href="#">legal disclosures</a>
      </Styled.Subtle> */}
    </Styled.Step1>
  );
};

export default Step1;
