import Logo from "assets/logo-klover.svg";
import { CUSTOM_EVENT_NAME } from "../constants";
import { sendOnboardingEvent } from "../tracking";
import * as Styled from "../App.styles";

interface Step3Props {
  activeStep: number;
}

const Step3 = ({ activeStep }: Step3Props) => {
  const handleOnDownloadClick = () => {
    sendOnboardingEvent({ action: CUSTOM_EVENT_NAME.DOWNLOAD_KLOVER });
    location.href = "https://cash.klover.app/PuAe/wa3naim5"
  }

  return (
    <Styled.Step3 $activeStep={activeStep}>
      <Styled.Logo src={Logo} />

      <Styled.Title>Success!</Styled.Title>

      <Styled.Description>
        Click the button below to get the Klover app on your phone. Excited to
        have you onboard!
      </Styled.Description>

      <Styled.Button
        fullWidth
        onClick={handleOnDownloadClick}
      >
        Download Klover
      </Styled.Button>
    </Styled.Step3>
  );
};

export default Step3;
