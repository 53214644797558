import ga from "./ga";
import mp from "./mparticle";
import { UaEventOptions } from "react-ga4/types/ga4";

export const trackPageView = () => {
  ga.trackPageView();
  mp.trackPageView();
};

export const sendCustomEvent = (event) => {
  ga.sendCustomEvent(event);
  mp.sendCustomEvent(event);
};

export const sendOnboardingEvent = (actionLabel: Pick<UaEventOptions, 'action' |'label'>) => {
  const event = {
    category: 'onboarding',
    ...actionLabel,
  };
  sendCustomEvent(event);
};

export default {
  trackPageView,
  sendCustomEvent,
  sendOnboardingEvent,
};
