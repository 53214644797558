import React from "react";
import Step1 from "steps/Step1";
import Step2 from "steps/Step2";
import Step3 from "steps/Step3";
import { ConfirmationResult, RecaptchaVerifier, User, getAuth } from "firebase/auth";
import { PAGE_TITLE } from "./constants";
import { auth } from "firebase";
import { trackPageView } from "tracking";
import * as Styled from "./App.styles";

const App = () => {
  const currentUser = getAuth().currentUser;
  const [activeStep, setActiveStep] = React.useState<number>(currentUser ? 3 : 1);
  const [user, setUser] = React.useState<User | null>(currentUser);
  const [phone, setPhone] = React.useState<string>("");
  const [errorPhoneAlreadyExists, setErrorPhoneAlreadyExists] =
    React.useState(false);
  const [confirmationResult, setConfirmationResult] =
    React.useState<ConfirmationResult>();

  React.useEffect(() => {
    if (typeof window !== "undefined" && !window.recaptchaVerifier) {
      // Ensure the DOM is ready and the `window.recaptchaVerifier` is not already set
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
        }
      ); // Passing the correct `auth` instance here
      window.recaptchaVerifier.render();
    }
  }, []);

  React.useEffect(() => {
    window.document.title = PAGE_TITLE[`STEP_${activeStep}`];
    trackPageView();
  }, [activeStep]);

  return (
    <Styled.App>
      <Step1
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        setUser={setUser}
        setPhone={setPhone}
        errorPhoneAlreadyExists={errorPhoneAlreadyExists}
        setErrorPhoneAlreadyExists={setErrorPhoneAlreadyExists}
        setConfirmationResult={setConfirmationResult}
      />

      <Step2
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        user={user}
        phone={phone}
        setErrorPhoneAlreadyExists={setErrorPhoneAlreadyExists}
        confirmationResult={confirmationResult}
        setConfirmationResult={setConfirmationResult}
      />

      <Step3 activeStep={activeStep} />

      <Styled.RecaptchaContainer id="recaptcha-container" />
    </Styled.App>
  );
};

export default App;
