import { gql } from "@apollo/client";

export const linkFirebaseMutation = gql`
  mutation LinkFirebase($input: LinkFirebaseInput!) {
    linkFirebase(input: $input) {
      ... on LinkFirebaseSuccess {
        points {
          amount
        }
        userId
      }
      ... on LinkFirebaseError {
        message
      }
      ... on LinkFirebaseIsVoipError {
        message
      }
    }
  }
`;

export default linkFirebaseMutation;
