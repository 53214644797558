import mParticle, { SDKEventAttrs } from "@mparticle/web-sdk";
import mixpanelKit from '@mparticle/web-mixpanel-kit';
import brazeKit from '@mparticle/web-braze-kit';

const mParticleConfig = {
  isDevelopmentMode: !import.meta.env.PROD,
  identityCallback: function (result) {
    // console.log('mparticle', 'identityCallback', result);
    // if (
    //   result &&
    //   result.body &&
    //   result.body.matched_identities &&
    //   result.body.matched_identities.email
    // ) {
    //    handle matched identity
    // }
  },
};

mixpanelKit.register(mParticleConfig);
brazeKit.register(mParticleConfig);
  
// mParticle.use(mParticle.Mixpanel);
mParticle.init(
  import.meta.env.VITE_MPARTICLE_API_KEY,
  mParticleConfig
);

export const login = ({ email, userId }) => {
  const identityRequest = {
    userIdentities: {
      email,
      customerid: userId,
    },
  };

  const identityCallback = function(result) { 
    // You can check if there's a populated user object, otherwise there was an error
      // You can also inspect the result.httpCode - see below for a description of the supported codes
      // console.log('mparticle login', result);
      if (result.getUser()) {
        // proceed with login
      } else {
        //the IDSync call failed - see below for more details on failed requests
      }
  };

  mParticle.Identity.login(identityRequest, identityCallback);
}

export const logout = () => {
  const identityCallback = function(result) { 
    if (result.getUser()) { 
      //proceed with logout 
    } 
  };
  mParticle.Identity.logout({}, identityCallback);
}

export const sendCustomEvent = (data: SDKEventAttrs) => {
  const { action } = data;
  delete data.action;
  if (action) {
    mParticle.logEvent(
      action.toString(),
      mParticle.EventType.Navigation,
      {
        page: window.location.toString(),
        'referring-page': document.referrer,
        ...data,
      }
    );
  }
}

export const trackPageView = () => {
  mParticle.logPageView(
    window.document.title,
    {
      page: window.location.toString(),
      'referring-page': document.referrer
    },
  );
}

export default {
  trackPageView,
  sendCustomEvent,
};