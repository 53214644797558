import ReactGA from "react-ga4";
import { UaEventOptions } from "react-ga4/types/ga4";
ReactGA.initialize(import.meta.env.VITE_GA_ID);

export const trackPageView = () => 
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: window.document.title,
  });

export const sendCustomEvent = (event: UaEventOptions) => {
  /*
    event example
    {
      category: "your category",
      action: "your action",
      label: "your label", // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    }
  */
  ReactGA.event(event);
};

export default {
  trackPageView,
  sendCustomEvent,
};
